// import global style and font here
import "@fontsource/oswald"
import "./src/style/global.scss"

// stop scroll to top for all Page

// export const shouldUpdateScroll = () => {
//   return false
// }

// Stop scroll to top only Category page
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `/category/health`,
    `/category/fitness`,
    `/category/technology`,
    `/category/community`,
    `/category/news-events`,
  ]
  const scrollToTop = `/blog/`
  // if the new route is part of the list above, prevent scroll to top.
  if (scrollToTopRoutes.includes(pathname)) {
    return false
  }
  if (pathname.includes(scrollToTop)) {
    return false
  }
  return true
}
