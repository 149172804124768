exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-posts-js": () => import("./../../../src/templates/categoryPosts.js" /* webpackChunkName: "component---src-templates-category-posts-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-go-vap-js": () => import("./../../../src/templates/go-vap.js" /* webpackChunkName: "component---src-templates-go-vap-js" */),
  "component---src-templates-group-training-js": () => import("./../../../src/templates/group-training.js" /* webpackChunkName: "component---src-templates-group-training-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-landing-page-template-js": () => import("./../../../src/templates/landing-page-template.js" /* webpackChunkName: "component---src-templates-landing-page-template-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-memberships-js": () => import("./../../../src/templates/memberships.js" /* webpackChunkName: "component---src-templates-memberships-js" */),
  "component---src-templates-our-team-js": () => import("./../../../src/templates/our-team.js" /* webpackChunkName: "component---src-templates-our-team-js" */),
  "component---src-templates-personal-training-js": () => import("./../../../src/templates/personal-training.js" /* webpackChunkName: "component---src-templates-personal-training-js" */),
  "component---src-templates-thao-dien-js": () => import("./../../../src/templates/thao-dien.js" /* webpackChunkName: "component---src-templates-thao-dien-js" */)
}

